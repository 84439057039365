import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	loginState,
	...rest
}) => {
	const { isAuthenticated, loading } = loginState;

	return (
		<Route
			{...rest}
			render={(props) => {
				if (isAuthProtected && !isAuthenticated && !loading) {
					return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
				}

				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		loginState: state.Login,
	};
};

export default connect(mapStateToProps)(AppRoute);
